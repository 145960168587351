<script setup lang="ts">
   import { XMarkIcon } from '@heroicons/vue/20/solid';
   import type { JSX } from 'vue/jsx-runtime';

   const props = defineProps({
      title: {
         type: String,
         required: false,
      },
      size: {
         type: String,
         default: 'md',
      },
      preserveLog: {
         type: Boolean,
         default: false,
      },
      noPadding: {
         type: Boolean,
         default: false,
      },
      isMobileFullWidth: {
         type: Boolean,
         default: false,
      },
   });
   const openDialog = defineModel<boolean>('openDialog', {
      required: true,
   });

   const widthClasses = computed(() => {
      switch (props.size) {
         case 'xs':
            return 'mx-4  max-w-xs';
         case 'sm':
            return 'mx-4  max-w-md';
         case 'md':
            return 'mx-4  max-w-xl';
         case 'lg':
            return 'mx-4  max-w-3xl';
         case 'xl':
            return ' mx-4 max-w-5xl';
         case 'xxl':
            return ' mx-4  max-w-5xl xl:max-w-screen-xl ';
      }
   });
   defineSlots<{
      default: (props: {}) => JSX.Element;

      header?: (props: {}) => JSX.Element;
   }>();
   const slot = useSlots();

   const closeDialog = () => {
      if (props.preserveLog) {
         return;
      }
      openDialog.value = false;
   };
   const { locale } = useI18n();
</script>

<template>
   <Teleport to="body" v-if="openDialog">
      <div
         v-if="openDialog"
         @click.prevent="closeDialog"
         @click.stop=""
         :class="[
            locale === 'ar' ? 'rtl' : 'ltr',
            isMobileFullWidth ? 'hidden sm:flex' : '',
         ]"
         class="overflow-y-auto overflow-x-hidden flex justify-center items-center fixed top-0 bottom-0 right-0 z-50 w-full bg-primary-900/40 max-h-full"
      >
         <div
            @click.stop=""
            class="w-full m-auto"
            :class="[widthClasses, isMobileFullWidth ? '' : '']"
         >
            <div class="relative bg-white rounded-lg shadow">
               <div
                  v-if="!slot.header"
                  class="flex items-center justify-between py-3 md:py-4 px-4 md:px-5 rounded-t"
               >
                  <h3 class="text-xl font-medium">
                     {{ title }}
                  </h3>

                  <XMarkIcon
                     @click.prevent="() => (openDialog = false)"
                     class="text-black cursor-pointer w-7 h-7"
                  />
               </div>
               <div v-else>
                  <slot name="header" />
               </div>
               <div
                  :class="noPadding ? 'p-0' : 'py-3 md:py-4 px-4 md:px-5'"
                  class="max-h-[90dvh] overflow-y-auto"
               >
                  <slot />
               </div>
            </div>
         </div>
      </div>
   </Teleport>
   <BaseSideMenu
      v-if="isMobileFullWidth"
      v-model:openSideBar="openDialog"
      @close="closeDialog"
   >
      <slot />
   </BaseSideMenu>
</template>
